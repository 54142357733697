<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 20:51:42
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-08 14:23:25
-->
<template>
  <div class="qj-layout-menus">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      @select="handleSelect"
      router
    >
      <el-menu-item
        v-for="(item, i) in menus"
        :key="i"
        :index="item.url"
        :style="{ width: `${item.title.length * 16 + 40}px` }"
      >
        {{ item.title }}
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
      menus: [
        {
          key: "1",
          title: "首页",
          url: "/home"
        },
        {
          key: "2",
          title: "抢单大厅",
          url: "/grab-single"
        },
        {
          key: "3",
          title: "产品中心",
          url: "/product"
        },
        {
          key: "4",
          title: "客户案例",
          url: "/customer-case"
        },
        {
          key: "5",
          title: "平台资讯",
          url: "/information"
        },
        {
          key: "6",
          title: "营销活动",
          url: "/marketing"
        },
        {
          key: "7",
          title: "平台介绍",
          url: "/platform"
        },
        {
          key: "8",
          title: "安装教程",
          url: "/install-tutorial"
        }
      ]
    };
  },
  watch: {
    $route() {
      this.activeIndex = "";
      let menu = this.menus.find(item => {
        return this.$route.path.indexOf(item.url) != -1;
      });
      if (menu) {
        this.activeIndex = menu.url;
      }
    }
  },
  mounted() {
    this.activeIndex = "";
    let menu = this.menus.find(item => {
      return this.$route.path.indexOf(item.url) != -1;
    });
    if (menu) {
      this.activeIndex = menu.url;
    }
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      // let menu = this.menus.find(item => {
      //   return item.key == key;
      // });
      // this.activeIndex = menu.key;
      // if (this.$route.path != menu.url) {
      //   this.$router.push(menu.url);
      // }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
