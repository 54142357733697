<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 20:51:42
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-21 10:53:33
-->
<template>
  <div class="qj-layout-footers">
    <div class="footers-body-one">
      <div class="content">
        <div class="contact-us">
          <div class="title">
            联系我们
          </div>
          <div class="body">
            <div class="item" v-for="(item, i) in data.wxGroup" :key="i">
              <div>
                微信交流群：<span :ref="`wxref${i}`">{{ item }}</span>
              </div>
              <el-popover placement="top-start" width="200" trigger="hover">
                <img
                  slot="reference"
                  src="../../../assets/img/common/img-copy-icon.png"
                />
                <div>
                  <img
                    style="width: 100%;height: 100%;"
                    :src="$utils.picturePrefix(data.wxImage)"
                  />
                </div>
              </el-popover>
            </div>
            <div class="item" v-for="(item, j) in data.qqGroup" :key="`qq${j}`">
              <div>
                &nbsp;QQ交流群：
                <span :ref="`qqref${j}`">{{ item }}</span>
              </div>
              <el-popover placement="top-start" width="200" trigger="hover">
                <img
                  slot="reference"
                  src="../../../assets/img/common/img-copy-icon.png"
                />
                <div>
                  <img
                    style="width: 100%;height: 100%;"
                    :src="$utils.picturePrefix(data.qqImage)"
                  />
                </div>
              </el-popover>
            </div>
            <div class="item">
              <div>我们的地址：{{ data.address }}</div>
            </div>
          </div>
        </div>

        <div class="product-service">
          <div class="title">
            产品服务
          </div>
          <div class="body">
            <div class="item">
              <div v-for="(item, i) in data.productService" :key="i">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>

        <div class="qr-code">
          <div class="title">
            小程序进入
          </div>
          <div class="qr-code-body">
            <div class="item">
              <img :src="$utils.picturePrefix(data.serviceProcess)" />
              <div>能匠到家客服</div>
            </div>
            <div class="item">
              <img :src="$utils.picturePrefix(data.smallProcess)" />
              <div>能匠到家小程序</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footers-body-two">
      <div class="text-left">
        Copyright © 2021 安徽泉界商贸有限公司
      </div>
      <div class="text-right">
        <span @click="openRcord">皖ICP备2021006606号-4</span>
        <!-- <span>京公网安备11010802023605号</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  mounted() {
    this.getIntroduce();
  },
  methods: {
    getIntroduce() {
      this.$api.getIntroduce().then(res => {
        this.data = res.data;
        sessionStorage.setItem("contactPhone", this.data.contactPhone || "");
        if (res.data && res.data.wxGroup) {
          res.data.wxGroup = res.data.wxGroup.split(",");
        }
        if (res.data && res.data.qqGroup) {
          res.data.qqGroup = res.data.qqGroup.split(",");
        }
        if (res.data && res.data.productService) {
          res.data.productService = res.data.productService.split(",");
        }
      });
    },
    /**
     * 复制账号
     */
    copyInviteCode(inviteCode) {
      let div = this.$refs[inviteCode][0];
      if (document.body.createTextRange) {
        let range = document.body.createTextRange();
        range.moveToElementText(div);
        range.select();
      } else if (window.getSelection) {
        let selection = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(div);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        console.warn("none");
      }
      document.execCommand("Copy");
      this.$message({
        message: "恭喜你，复制成功！",
        type: "success"
      });
    },
    /**
     * 打开备案号网站
     */
    openRcord() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
