<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 20:51:42
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-08 14:25:50
-->
<template>
  <div class="qj-layout-headers">
    <img
      class="headers-icon"
      src="../../../assets/img/common/img-common-icon.png"
    />
    <menus></menus>
    <!-- <div class="headers-messages">
      <img
        class="messages-icon"
        src="../../../assets/img/common/img-common-news-icon.png"
      />
      <div class="messages-text">10</div>
    </div> -->
    <div class="headers-operation" v-if="!isLogin">
      <el-button @click="goLogin">登录</el-button>
      <el-button type="primary" @click="goRegister">注册</el-button>
    </div>
    <div class="headers-avatar" v-if="isLogin">
      <el-avatar
        :src="$utils.picturePrefix(user.nickPhoto)"
        v-if="!user.openId"
      ></el-avatar>
      <el-avatar :src="user.nickPhoto" v-if="user.openId"></el-avatar>
      <el-popover
        placement="bottom-end"
        width="254"
        popper-class="headers-avatar-popper"
        v-model="openPopover"
      >
        <div class="popover-info">
          <!-- <el-avatar :src="user.nickPhoto"></el-avatar> -->
          <el-avatar
            :src="$utils.picturePrefix(user.nickPhoto)"
            v-if="!user.openId"
          ></el-avatar>
          <el-avatar :src="user.nickPhoto" v-if="user.openId"></el-avatar>
          <p class="name text-ellipsis" :title="user && user.name">
            {{ user && user.name }}
          </p>
          <div class="level">
            <div class="level-left">
              <i class="iconfont icon-shifu"></i>
            </div>
            <div class="level-right">
              {{ user && user.level && user.level.name }}
            </div>
          </div>
          <!-- <div class="level-exp">
            <div class="exp-progress">
              <span class="level-text">L1</span>
              <el-progress
                :percentage="
                  user && user.level
                    ? (user.allMoney / user.level.nextNumerical) * 100
                    : 0
                "
                :show-text="false"
              ></el-progress>
              <span class="level-text">L2</span>
            </div>
            <div class="exp-text">
              <div class="exp-text-title">收益</div>
              <div class="exp-text-level">
                {{ user && user.level && user.allMoney }}/{{
                  user && user.level && user.level.nextNumerical
                }}
              </div>
            </div>
          </div> -->
          <el-button @click="goUserInfo"> 个人中心 </el-button>
          <div class="login-out" @click="outLogin">
            <i class="iconfont icon-tuichu1"></i>
            <span>退出</span>
          </div>
        </div>
        <!-- <ul class="popover-ul">
          <li :class="{select: isSlectCenter}" @click="goUserInfo">个人中心</li>
          <li>退出登录</li>
        </ul> -->
        <span
          slot="reference"
          class="el-dropdown-link"
          :class="{ open: openPopover }"
        >
          <span class="text-ellipsis" :title="user && user.name">{{
            user && user.name
          }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Menus from "../Menus";
export default {
  components: {
    Menus,
  },
  data() {
    return {
      isLogin: false,
      openPopover: false,
      isSlectCenter: false, // 是否选中个人中心
      user: {},
    };
  },
  mounted() {
    this.user = this.$store.getters.user;
    this.user.nickPhoto =
      "https://www.nengjiangdaojia.com/water_purification/opt/upload/img/1631850282040935786.png";
    console.log(this.user);
    this.isLogin = this.$store.getters.isPower;
    this.isSlectCenter = this.$route.path.indexOf("/personal-center") != -1;
  },

  watch: {
    // isPower
    "$store.getters.user": {
      handler(newDate) {
        this.user = newDate;
      },
      // 对象内部的属性监听，也叫深度监听
      deep: true,
    },
    "$store.getters.isPower": {
      handler(newDate) {
        this.openPopover = false;
        this.isLogin = newDate;
      },
      // 对象内部的属性监听，也叫深度监听
      deep: true,
    },
  },

  methods: {
    // 退出登录
    outLogin() {
      localStorage.clear();
      // this.$store.dispatch("getUser", true);
      this.$store.dispatch("setUserNull");
      this.$router.push("/login");
    },
    // 前往用户中心
    goUserInfo() {
      this.openPopover = false;
      if (this.$route.path.indexOf("/personal-center/user/info") == -1) {
        this.$router.push("/personal-center/user/info");
      }
    },
    goLogin() {
      if (this.$route.path.indexOf("/login") == -1) {
        this.$router.push("/login");
      }
    },
    goRegister() {
      if (this.$route.path.indexOf("/register") == -1) {
        this.$router.push("/register");
      }
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
