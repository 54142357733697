<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 20:51:42
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-08 18:50:40
-->
<template>
  <div class="qj-layout" id="qjLayout">
    <headers></headers>
    <div style="height:60px"></div>

    <div
      class="qj-layout-main"
      :style="{ backgroundColor: !$route.meta.color ? '#fff' : '#f9fafe' }"
    >
      <router-view></router-view>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import Headers from "./components/Headers";
import Footers from "./components/Footers";
import { permissions } from "@/utils/permissions";

export default {
  components: {
    Headers,
    Footers
  },
  mounted() {
    // console.log(this.$route.meta.color);
    if (permissions()) {
      this.getUserInfo();
    }
  },

  methods: {
    getUserInfo() {
      this.$store.dispatch("getUser");
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
